import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const panes = document.querySelectorAll('.pane');

if (panes) {

    const MasterTimeline = gsap.timeline();

    panes.forEach(pane => {
        if (pane.getAttribute('data-background') != '') {
            const paneBackground = getComputedStyle(document.documentElement).getPropertyValue(`--${pane.getAttribute('data-background')}`);
            const paneColor = getComputedStyle(document.documentElement).getPropertyValue(`--${pane.getAttribute('data-background')}-color`);
            const defaultHeadingColor = getComputedStyle(document.documentElement).getPropertyValue(`--pe-secondary`);

            ScrollTrigger.create({
                trigger: pane,
                markers: false,
                start: "top 50%",
                end: "bottom 50%",

                onEnter: () => {
                    gsap.to('body', {
                        duration: 1.0,
                        backgroundColor: paneBackground,
                        color: paneColor,
                    });

                    if(pane.getAttribute('data-background') !== 'bg--primary' && pane.getAttribute('data-background') !== 'bg--secondary') {
                        document.documentElement.style.setProperty('--pe-heading-color', paneColor);
                        document.body.classList.add('is-dark');
                    }
                    else {
                        document.documentElement.style.setProperty('--pe-heading-color', defaultHeadingColor);
                        document.body.classList.remove('is-dark');
                    }
                },

                onEnterBack: () => {
                    gsap.to('body', {
                        duration: 1.0,
                        backgroundColor: paneBackground,
                        color: paneColor,
                    });

                    if(pane.getAttribute('data-background') !== 'bg--primary' && pane.getAttribute('data-background') !== 'bg--secondary') {
                        document.documentElement.style.setProperty('--pe-heading-color', paneColor);
                        document.body.classList.add('is-dark');
                    }
                    else {
                        document.documentElement.style.setProperty('--pe-heading-color', defaultHeadingColor);
                        document.body.classList.remove('is-dark');
                    }
                },
            });
        }
    })
}
