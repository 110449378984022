import tabAnimation from "./animations/tabs";

class Tabs {
    constructor(tabs, options) {
        if (!tabs) {
            return;
        }

        this.tabNav = tabs;
        this.options = options || {};
        this.tabItems = [...tabs.querySelectorAll('[data-tab-target]')];
        this.activeState = 'is-active';
        this.defaultActiveTab = "openTab" in this.options ? this.options.openTab : this.tabItems[0].getAttribute('data-tab-target');
        this.init();
    }

    init() {
        // Remove active classes if exists
        this.closeTabs();
        this.setActiveTab(this.defaultActiveTab);
        // this.clickEvent();
        this.resizeEvent();
        
        if(!document.body.classList.contains('wp-admin')) {
            this.scrollEvent();
        }
    }

    // Get content block el corresponding with navItem
    getContentBlock(value) {
        return document.querySelector(`[data-tab-content="${value}"]`);
    }

    setActiveTab(value) {
        const tabContent = document.querySelector(`[data-tab-content="${value}"]`);

        document.querySelector(`[data-tab-target="${value}"]`).classList.add(this.activeState);
        tabContent.classList.add(this.activeState);

        this.setTabContainerHeight(value);
    }

    setTabContainerHeight(value) {
        const activeTab = document.querySelector(`[data-tab-content="${value}"].is-active`);
        const activeTabHeight = activeTab.offsetHeight;

        activeTab.closest('.tabs__container').style.setProperty('--height', `${activeTabHeight + 20}px`);
    }

    resizeEvent() {
        window.addEventListener('resize', () => {
            this.setTabContainerHeight(this.currentActiveTab());
        });
    }

    scrollEvent() {
        const tabsContainer = document.querySelector(`[data-tab-content="${this.currentActiveTab()}"`).closest('.pane');

        const that = this;

        function handleIntersection(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    that.removeActiveTab(that.currentActiveTab());
                    that.setActiveTab(entry.target.getAttribute('data-tab-image'));
                }
            });
        }

        const observer = new IntersectionObserver(handleIntersection, {
            root: null, // viewport wordt gebruikt als het root element
            rootMargin: '0px', // geen marge rondom het viewport
            threshold: 0.75 // drempel van 0.5, wat betekent dat wanneer de helft van het element in het viewport is, de observatie plaatsvindt
        });

        if(window.matchMedia('(min-width: 992px)').matches) {
            tabsContainer.querySelectorAll('.tab__image').forEach(tabImage => {1
                observer.observe(tabImage);
            });
        }
    }

    currentActiveTab() {
        return this.tabNav.querySelector(`.${this.activeState}`).getAttribute('data-tab-target');
    }

    removeActiveTab(value) {
        document.querySelector(`[data-tab-target="${value}"]`).classList.remove(this.activeState);
        document.querySelector(`[data-tab-content="${value}"]`).classList.remove(this.activeState);
    }

    closeTabs() {
        this.tabItems.forEach(navItem => {
            const attrValue = navItem.getAttribute('data-tab-target');
            this.removeActiveTab(attrValue);
        });
    }

    clickEvent() {
        this.tabNav.addEventListener('click', (e) => {
            if (e.target.getAttribute('data-tab-target')) {
                this.removeActiveTab(this.currentActiveTab());
                this.setActiveTab(e.target.getAttribute('data-tab-target'));

                this.tabNav.closest('.pane').querySelector(`[data-tab-image="${e.target.getAttribute('data-tab-target')}"]`).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        });
    }
}

if (document.querySelectorAll('[data-tab="tabs"]')) {
    document.querySelectorAll('[data-tab="tabs"]').forEach(el => {
        new Tabs(el);

        if(!document.body.classList.contains('wp-admin')) {
            if(window.matchMedia('(min-width: 992px)').matches) {
                new tabAnimation(el);
            }

            window.matchMedia('(min-width: 992px)').addListener(function(e) {
                if(e.matches) {
                    new tabAnimation(el);
                }
            });
        }
    });
}
