let filterloaded = true;
class filter {
    constructor(toggler, sidebar) {
        this.toggler = toggler;
        this.sidebar = sidebar;
        this.activeClass = "filters--is-open";

        if (!this.toggler && !this.sidebar) {
            return;
        }

        this.checkFilter();
    }

    checkFilter() {
        document.addEventListener('facetwp-refresh', () => {

            const nonEmptyValues = Object.values(FWP.facets).filter(value => value !== null && (!Array.isArray(value) || value.length > 0));

            if (nonEmptyValues.length === 0) {
                this.toggler.forEach(toggler => {
                    toggler.remove();
                });
            }
        });
    }

    clickEvent(el = this.toggler) {
        const that = this;
        el.forEach((toggle) => {
            toggle.addEventListener("click", (e) => {
                that.sidebarToggle(that.sidebar);
                if (e) {
                    filterloaded = false;
                }
            });
        });

        this.sidebar
            .querySelector(".filters__close")
            .addEventListener("click", (e) => {
                this.sidebarToggle(this.sidebar);
            });
    }

    sidebarToggle(elmnt) {
        elmnt.classList.toggle(this.activeClass);
    }
}

const sidebar = document.querySelector(".filters");
const filterToggler = document.querySelectorAll(".filters__button");

if (sidebar && filterToggler) {
    const shopFilter = new filter(filterToggler, sidebar);
    shopFilter.clickEvent();
}
