import Swiper from "../../node_modules/swiper/swiper-bundle.esm";

(function () {
    const breakpoints = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1280,
        xxl: 1680,
    };

    const effects = [
        'slide',
        'fade',
        'cube',
        'coverflow',
        'flip',
        'creative',
        'cards'
    ];

    const swiperSizes = {
        fourth: 4,
        half: 2,
        third: 3,
        full: 1,
    };

    const swiperOptions = {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        autoHeight: false,
        slideClass: 'swiper__slide',
        createElements: true,
        watchOverflow: true,

        lazy: {
            // Disable preloading of all images
            preloadImages: false,
            // Enable lazy loading
            lazy: true,
            //  tell swiper to load images before they appear
            // loadPrevNext: true,
            // amount of images to load
            // loadPrevNextAmount: 2,
            // Disable preloading of all images
        },


        // Navigation arrows
        navigation: {
            nextEl: '.swiper__button--next',
            prevEl: '.swiper__button--prev',
            disabledClass: 'swiper__button--disabled'
        },

        speed: 500,
        spaceBetween: 40,
        effect: effects[0],
        grabCursor: true,

        breakpoints: {
            [`${breakpoints.xs}`]: {
                slidesPerView: 1.2,
                centeredSlides: true,
            },
            [`${breakpoints.lg}`]: {
                slidesPerView: 2,
                centeredSlides: false,
            }
        }
    };

    const swipers = [...document.querySelectorAll('.swiper')];

    swipers.forEach(swiper => {
        // =-=-=-=-=-=-=-=-=-=-=-=- Begin default swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        Object.entries(swiperSizes).forEach(function (swiperSize) {
            const [key, value] = swiperSize;
            if (swiper.classList.contains(`swiper--${key}`)) {
                swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                    slidesPerView: value,
                    centeredSlides: false,
                };
            }
        });

        if (swiper.classList.contains('swiper--posts')) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = false;

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 4,
            };
        }

        if (swiper.classList.contains('swiper--expertises')) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = false;
            swiperOptions.effect = false;

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 4,
            };
        }


        if (swiper.classList.contains('swiper--projects')) {
            swiperOptions.autoHeight = false;
            swiperOptions.mousewheel = true;
            swiperOptions.loop = false;
            swiperOptions.effect = "creative";
            swiperOptions.slidesPerView = 1.1;
            swiperOptions.creativeEffect = {
                prev: {
                    shadow: true,
                    translate: [0, 0, -100],
                },
                next: {
                    translate: ["calc(100% + 20px)", 0, 0],
                },
            };

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                centeredSlides: false,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                creativeEffect: {
                    prev: {
                        shadow: true,
                        translate: [0, 0, -100],
                    },
                    next: {
                        translate: ["calc(100% + 40px)", 0, 0],
                    },
                },
            };
        }

        if (swiper.classList.contains('swiper--text')) {
            swiperOptions.speed = 1500;
            swiperOptions.autoplay = {
                delay: 3500,
            };
            swiperOptions.loop = true;
            swiperOptions.effect = "fade";
            swiperOptions.slidesPerView = 1;
            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 1,
            };

            const swiperProgressBar = document.querySelector('.progress-bar');
            swiperProgressBar.classList.add('swiper-progress-bar');

            const progressBarFill = document.createElement('div');
            progressBarFill.classList.add('swiper-progress-bar-fill');
            swiperProgressBar.appendChild(progressBarFill);

            swiperOptions.on = {
                init: function () {
                    updateProgressBar();
                },
                slideChangeTransitionStart: function () {
                    resetProgressBar();
                },
                slideChangeTransitionEnd: function () {
                    updateProgressBar();
                }
            };

            function resetProgressBar() {
                progressBarFill.style.transition = 'none';
                progressBarFill.style.width = '0%';
                progressBarFill.offsetHeight;
            }

            function updateProgressBar() {
                const delay = swiperOptions.autoplay.delay;
                setTimeout(() => {
                    progressBarFill.style.transition = `width ${delay}ms linear`;
                    progressBarFill.style.width = '100%';
                }, 50);
            }
        }

        if (swiper.classList.contains('swiper--image')) {
            swiperOptions.autoHeight = false;
            swiperOptions.slidesPerView = 1;
            swiperOptions.spaceBetween = 10;

            swiperOptions.breakpoints[`${breakpoints.sm}`] = {
                slidesPerView: 1,
                spaceBetween: 10,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 1,
            };
        }

        // =-=-=-=-=-=-=-=-=-=-=-=- End default swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        // =-=-=-=-=-=-=-=-=-=-=-=- Begin Override swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        // If you want to overwrite a swiper carousel do it between the "Begin overwrite swiper carousels" and "End overwrite swiper carousels"

        if (swiper.classList.contains('swiper--hero')) {
            swiperOptions.autoHeight = false;
            swiperOptions.slidesPerView = 1;

            // Overrides the default breakpoint options with nothing, so the above options are used
            swiperOptions.breakpoints[`${breakpoints.xs}`] = {};
            swiperOptions.breakpoints[`${breakpoints.lg}`] = {};
        }

        if (swiper.classList.contains('swiper--logo')) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = true;
            swiperOptions.freeMode = true;
            swiperOptions.slidesPerView = 2;
            swiperOptions.speed = 4500;
            swiperOptions.autoplay = {
                delay: 0,
                disableOnInteraction: false,
            };

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 2,
            };

            swiperOptions.breakpoints[`${breakpoints.md}`] = {
                slidesPerView: 3,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 5,
            };
        }

        // =-=-=-=-=-=-=-=-=-=-=-=- End Override swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-

        const swiperDefault = new Swiper(swiper, swiperOptions);
    });
})();
