class createCssVar {
  constructor(element, cssVarName, cb) {
    this.root = document.documentElement;
    this.element = document.querySelector(element);
    this.cssVarName = cssVarName;
    this.setValue = cb;

    this.createVariable();
  }

  createVariable() {
    this.root.style.setProperty(
      this.cssVarName,
      this.setValue(this.element)
    );
  }
}

function setMenuHeight() {
  new createCssVar(
    ".main-header__desktop",
    "--megamenu-height",
    (element) => {
      const items = [...element.querySelectorAll('.mega-menu-submenu')];

      function getLargestMenuHeight(items) {
        const highestMenuItem = items.reduce((maxItem, currentItem) => {
          return currentItem.offsetHeight > (maxItem ? maxItem.offsetHeight : 0)
            ? currentItem
            : maxItem;
        }, null);

        return highestMenuItem?.offsetHeight;
      }

      return `${Math.round(getLargestMenuHeight(items))}px`;
    }
  );
}

const megaMenuHeight = document.addEventListener("DOMContentLoaded", setMenuHeight);
