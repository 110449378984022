function headerAnimation(header) {
    this.headerEle = document.querySelector(header);
    this.prevScrollPosition = window.pageYOffset;
    this.count = 0;
    this.state = "down";
    this.matchMedia = "(max-width: 3000px)";

    if (!this.headerEle || document.body.classList.contains("wp-admin")) {
        return;
    }

    this.init = () => {
        this.mediaQuery();
        this.observePixel();
    };

    this.mediaQuery = () => {
        if (window.matchMedia(this.matchMedia).matches) {
            this.active();
        } else {
            this.destroy();
        }

        window
            .matchMedia(this.matchMedia)
            .addEventListener("change", (event) => {
                if (event.matches) {
                    this.destroy();
                } else {
                    this.active();
                }
            });
    };

    this.scrollDirection = () => {
        if (this.count === 0) {
            this.prevScrollPosition = window.pageYOffset;
            this.count++;
        } else {
            this.count = 0;
            if (
                window.pageYOffset >= this.prevScrollPosition &&
                this.prevScrollPosition > 0
            ) {
                this.state = "up";
            } else {
                this.state = "down";
            }
        }
        // Change the global state after scolldirection is changed
        this.changeState();
    };

    this.changeState = (state = this.state) => {
        if (state === "up" && this.headerEle.getAttribute('data-menu') !== 'open') {
            this.headerEle.classList.add("is-hidden");
        } else if (state === "down") {
            this.headerEle.classList.remove("is-hidden");
        }
    };

    this.observePixel = () => {
        const pixelToWatch = document.querySelector("#pixel-to-watch");
        const hero = document.querySelector(".hero");

        const myObserver = new IntersectionObserver((elements) => {
            if (
                elements[0]?.boundingClientRect.y < 0 ||
                elements[1]?.isIntersecting
            ) {
                document
                    .querySelector(".main-header")
                    .classList.add("is-scrolled");
            } else {
                document
                    .querySelector(".main-header")
                    .classList.remove("is-scrolled");
            }
        });

        myObserver.observe(pixelToWatch);

        if (hero) {
            myObserver.observe(hero);
        }
    };

    // Destroy current instance
    this.destroy = () => {
        window.removeEventListener("scroll", this.scrollDirection);
    };

    // Activate current instance
    this.active = () => {
        window.addEventListener("scroll", this.scrollDirection);

        const that = this;

        const myObserver = new IntersectionObserver(elements => {
            if (elements[0].boundingClientRect.y < 0) {
                that.headerEle.classList.add('is-scrolled');
            }
            else {
                that.headerEle.classList.remove('is-scrolled');
            }
        });

        const pixelToWatch = document.querySelector("#pixel-to-watch");

        myObserver.observe(pixelToWatch);
    };
}

// Create new instance with constructor function
const mainheader = new headerAnimation(".main-header");

// initialize headerAnimation
if (!document.body.classList.contains("wp-admin")) {
    mainheader.init();
}
