class GridHelper {
    active() {
        document.body.insertAdjacentHTML(
            "beforeEnd",
            `
            <div id="container-grid-helper" class="container container--overlay-grid" data-grid-helper-container-size>
                    <div class="col col--grid-helper"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
            </div>
            <div class="grid-helper__info">
            </div>
        `
        );

        this.resize();
    }

    resize() {
        const observer = new ResizeObserver(() => {
            this.calcSizes();
        });

        observer.observe(
            document.querySelector("[data-grid-helper-container-size]")
        );

        return observer;
    }

    calcSizes() {
        let container = document.querySelector(
            "[data-grid-helper-container-size]"
        );
        let containerWidth = container.offsetWidth;

        let col = document.querySelector(".col");
        let colWidth = col.offsetWidth;
        let gutterSize = (containerWidth - colWidth * 12) / 12;

        let info = document.querySelector(".grid-helper__info");
        info.innerHTML = "";
        info.insertAdjacentHTML(
            "afterBegin",
            `
            <p>container width: ${containerWidth} px</p>
            <p>col size: ${colWidth} px</p>
            <p>gutter: ${gutterSize} px</p>
        `
        );
    }

    destroy() {
        document.querySelector(".container--overlay-grid").remove();
    }
}

let gridHelper = new GridHelper();
window.GridHelper = gridHelper;

// Useage in the console of the browser
// window.GridHelper.active();
// window.GridHelper.destroy();
