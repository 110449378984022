window.addEventListener("DOMContentLoaded", function(e) { 
    var videos = document.querySelectorAll(".btn--video");

    videos.forEach((button, index) => {
        button.addEventListener('click', () => {
            let video = button.nextElementSibling;
            let container = video.closest('.video__container');

            if(container.classList.contains('autoplay')) {
                // Pause the video
                video.muted = false; // Unmute the video
                video.pause();
                video.currentTime = 0;
                video.play();
                
                container.classList.remove('autoplay');
                container.classList.add('play');
            }

        });
    });
});
