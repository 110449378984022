import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollSmoother } from "gsap/dist/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

ScrollSmoother.create({
    smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
    effects: true, // looks for data-speed and data-lag attributes on elements
    smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
});

export default class tabAnimation {
    constructor(element) {
        if(!element) {
            return;
        }

        this.element = element;
        this.parentPane = this.element.closest('.pane')

        this.init();
    }

    init() {
        const imageTimeline = this.createTimeline({
            scrollTrigger: {
                start: "top bottom",
                end: `bottom 25%`,
                scrub: true,
                trigger: this.parentPane,
                markers: false,
            }
        });

        const tabContentTimeline = this.createTimeline({
            scrollTrigger: {
                start: "top 10%",
                end: `bottom bottom`,
                scrub: true,
                trigger: this.parentPane.querySelector('#pinned-content'),
                markers: false,
                pin: true
            }
        });

        this.imageParallax(imageTimeline);
        this.tabContent(tabContentTimeline);
    }

    imageParallax(timeline) {
        const images = this.parentPane.querySelectorAll('.tab__image .img-fluid');
        const imageWrappers = this.parentPane.querySelectorAll('.tab__image');
        
        images.forEach(image => {
            timeline.fromTo(image, {
                yPercent: -20,
            },{
                yPercent: 20,
            }, "<");
        });
    }

    tabContent(timeline) {
        const tabContents = this.parentPane.querySelectorAll('.tab__content');
    }

    createTimeline(options) {
        return gsap.timeline(options);
    }
}
