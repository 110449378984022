import gsap from "gsap";
import { GSDevTools } from "gsap/dist/GSDevTools";
import { CustomEase } from "gsap/dist/CustomEase";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import "./animations/background";

gsap.registerPlugin(GSDevTools, CustomEase, ScrollTrigger);

function strip_tags(_html /*you can put each single tag per argument*/) {
    var _tags = [], _tag = "";
    for (var _a = 1; _a < arguments.length; _a++) {
        _tag = arguments[_a].replace(/<|>/g, '').trim();
        if (arguments[_a].length > 0) _tags.push(_tag, "/" + _tag);
    }

    if (!(typeof _html == "string") && !(_html instanceof String)) return "";
    else if (_tags.length == 0) return _html.replace(/<(\s*\/?)[^>]+>/g, "");
    else {
        var _re = new RegExp("<(?!(" + _tags.join("|") + ")\s*\/?)[^>]+>", "g");
        return _html.replace(_re, '');
    }
}

function animateHero() {
    const hero = document.querySelector("[data-hero-animation]");
    const heroImage = document.querySelector(hero > ".hero__image");

    let polygon = {
        tx: 0,
        ty: 0,
        rx: 0,
        ry: 0,
        bx: 0,
        by: 0,
        lx: 0,
        ly: 0,
    };

    if (!hero) {
        return;
    }

    function updateCssVariable(name, value) {
        document.documentElement.style.setProperty(name, value);
    }

    function updateValues() {
        const innerHeight = window.innerHeight;
        const headerHeight = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--header-height");
        const verticalPadding = "50";

        let maxHeightContainer =
            innerHeight - headerHeight - verticalPadding * 2;
        let maxHeightContainerPercentage = Math.round(
            (maxHeightContainer / innerHeight) * 100
        );

        let fromTopBottom = (100 - maxHeightContainerPercentage) / 2;

        polygon.ty = fromTopBottom;
        polygon.ry = fromTopBottom;
        polygon.by = fromTopBottom;
        polygon.ly = fromTopBottom;
    }

    updateValues();

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".body",
            pin: false,
            start: "top top",
            end: `${window.innerHeight} 50%`,
            scrub: true,
            markers: false,
        },
    });

    const tl2 = gsap.timeline({
        scrollTrigger: {
            trigger: ".body",
            start: "top top",
            end: "40% top",
        },
    });

    tl2.to(hero, {
        "--clip-hero-padding": `var(--header-height) 4vw 4vw`,
        duration: 1.2,
        ease: "power2.inOut",
    });

    tl2.fromTo(
        heroImage,
        {
            "--hero-border-radius": `0`,
        },
        {
            "--hero-border-radius": `1rem`,
            duration: 1.2,
            ease: "power2.inOut",
        },
        "<"
    );

    tl.to("[data-hero-text-animation]", {
        yPercent: () => {
            let value = 150;
            if (window.matchMedia('(min-width: 768px)').matches) {
                value = 175;
            }

            return value;
        },
    });

    tl.to('.hero__sidebar', {
        yPercent: () => {
            let value = -25;

            if (window.matchMedia('(min-width: 768px)').matches) {
                value = -50;
            }

            return value;
        },
    }, '<+=0.2');
}

function animateTextOnScroll() {
    const textContainers = document.querySelectorAll("[data-text-reveal]");

    textContainers.forEach(container => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: container,
                start: "top bottom",
                end: "top top",
                scrub: true,
                markers: false,
            },
        });

        tl.fromTo(
            container,
            {
                opacity: 0.5,
                scale: 1.3,
            },
            {
                opacity: 1,
                scale: 1,
            }
        );

        tl.fromTo(
            container,
            {
                y: 100,
            },
            {
                y: -100,
            },
            "<"
        );
    });
}

function animateGridOnScroll() {
    const images = document.querySelectorAll("[data-reveal-grid-image]");
    const imageContainers = document.querySelectorAll(
        "[data-reveal-grid-image] > .image__container"
    );

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: images,
            start: "40% bottom",
        },
    });

    tl.to(imageContainers, {
        "--clip-image-wrapper": `0 0, 100% 0, 100% 100%, 0 100%`,
        ease: "power1.out",
        duration: 1.3,
        stagger: {
            from: "center",
            ease: "power1.out",
            amount: 0.4,
        },
    });

    const tl2 = gsap.timeline({
        scrollTrigger: {
            trigger: images,
            start: "40% bottom",
            end: "+=50%",
            scrub: true,
        },
    });

    tl2.fromTo(
        images,
        {
            y: 150,
        },
        {
            y: -75,
            duration: 3,
            stagger: {
                from: "center",
                ease: "expoScale(0.5,7,none)",
                amount: 0.4,
            },
        }
    );

    return { tl, tl2 };
}

function switchBackgroundColor() {
    const panes = document.querySelectorAll("[data-background-transition]");

    panes.forEach((pane) => {
        const bgColorTransition = pane.getAttribute(
            "data-background-transition"
        );
        const bgColor = window
            .getComputedStyle(pane)
            .getPropertyValue(`--${bgColorTransition}`);

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: pane,
                start: "20% bottom",
                end: "top 20%",
                scrub: true,
            },
        });

        // tl.to(pane, { backgroundColor: `var(${bgColorTransition})` });

        tl.to(pane, {
            "--pane-bg": `var(${bgColorTransition})`,
            "--pane-color": "white",
        });
    });
}

if (!document.body.classList.contains("wp-admin")) {
    document.addEventListener("DOMContentLoaded", () => {
        const heroAnimation = animateHero();
        const masterTimeline = gsap.timeline({ paused: true });
        masterTimeline.add(animateGridOnScroll());

        animateTextOnScroll()

        switchBackgroundColor();
    });
}


function animateText(blocks) {
    const TextElements = document.querySelectorAll(blocks);
    TextElements.forEach(text => {

        // text.innerHTML = strip_tags(text.innerHTML, "b", "strong", "em", "span");
        const blockTl = gsap.timeline({
            scrollTrigger: {
                start: "top 100%",
                end: "bottom 50%",
                scrub: true,
                trigger: text,
                markers: false,
            }
        });
        blockTl.to(text, {
            backgroundSize: "100% 100%",
        });
    });

}

animateText('.editor--animated > *');



animateCards(".card__wrapper", ".card-large");

function animateCards(wrapper, card) {
    const wrapperClass = wrapper;
    const cardsClass = card;
    const cardsWrappers = gsap.utils.toArray(wrapperClass);
    const cards = gsap.utils.toArray(cardsClass);

    cardsWrappers.forEach((wrapper, i) => {
        const card = cards[i];
        let scale = 1,
            rotation = 0;
        if (i !== cards.length - 1) {
            scale = 0.9 + 0.025 * i;
            rotation = -10;
        }

        const isMobile = window.innerWidth < 768; // Adjust width threshold as needed

        gsap.to(card, {
            scale: scale,
            rotationX: rotation,
            transformOrigin: "top center",
            ease: "none",
            scrollTrigger: {
                trigger: wrapper,
                start: "top " + (60 + 10 * i),
                end: !isMobile ? "bottom 550" : "bottom 100%",
                endTrigger: ".steps",
                scrub: true,
                pin: wrapper,
                pinSpacing: false,
                id: i + 1
            }
        });
    });
}

animateProjects(".card__wrapper--projects", ".card--project");

function animateProjects(wrapper, card) {
    const wrapperClass = wrapper;
    const cardsClass = card;
    const cardsWrappers = gsap.utils.toArray(wrapperClass);
    const cards = gsap.utils.toArray(cardsClass);

    cardsWrappers.forEach((wrapper, i) => {
        const card = cards[i];
        let scale = 1,
            rotation = 0;
        if (i !== cards.length - 1) {
            scale = 0.9 + 0.025 * i;
            rotation = -10;
        }
        gsap.to(card, {
            scale: scale,
            rotationX: rotation,
            transformOrigin: "top center",
            ease: "none",
            scrollTrigger: {
                trigger: wrapper,
                start: "center center",
                end: "bottom 80%",
                endTrigger: ".projects",
                scrub: true,
                pin: wrapper,
                pinSpacing: false,
                id: i + 1
            }
        });
    });
}
