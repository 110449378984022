import gsap from "gsap";
import { GSDevTools } from "gsap/dist/GSDevTools";
import { CustomEase } from "gsap/dist/CustomEase";
gsap.registerPlugin(GSDevTools, CustomEase);

class hamburgerMenu {
    constructor(iconToggle, menuContainer, matchMedia) {
        this.iconToggle = document.querySelector(iconToggle); // menu iconToggle;
        this.menuContainer = document.querySelector(menuContainer); // menu nav container
        this.matchMedia = matchMedia;
        this.menuIsOpen = false;
        this.header = document.querySelector("[data-header]");

        if (!this.iconToggle || !this.menuContainer) {
            return;
        }

        this.clickHandler = this.menuToggle.bind(this);
        this.mediaQuery();
    }

    active() {
        this.iconToggle.addEventListener("click", this.clickHandler);
    }

    menuToggle() {
        if (!this.menuIsOpen) {
            this.menuOpen();
        } else {
            this.menuClose();
        }

        this.menuIsOpen = !this.menuIsOpen;
    }

    menuOpen() {
        this.iconToggle.classList.add("is-active");
        this.menuContainer.classList.add("is-active");
        this.header.classList.add("is-fixed");
        this.menuSectionListener();
        this.header.setAttribute("data-menu", "open");
    }

    menuClose() {
        this.header.classList.remove("is-fixed");
        this.menuContainer.classList.remove("is-active");
        this.iconToggle.classList.remove("is-active");
        this.removeMenuSectionStates();
        this.header.setAttribute(
            "data-menu",
            localStorage.getItem("header-theme")
        );
    }

    mediaQuery() {
        if (window.matchMedia(this.matchMedia).matches) {
            this.active();
        } else {
            this.destroy();
        }

        window
            .matchMedia(this.matchMedia)
            .addEventListener("change", (event) => {
                if (event.matches) {
                    this.active();
                } else {
                    this.destroy();
                }
            });
    }

    removeMenuSectionStates() {
        this.menuContainer
            .querySelectorAll("[data-menu-section]")
            .forEach((section) => {
                section.classList.remove("is-active");
            });
    }

    menuSectionListener() {
        this.menuContainer.addEventListener("click", this.sectionToggle);
    }

    destroy() {
        this.iconToggle.removeEventListener("click", this.clickHandler);
        this.menuContainer.removeEventListener("click", this.sectionToggle);
        this.removeMenuSectionStates();
    }

    sectionToggle(e) {
        const menuItemAttr = "data-menu-toggle";
        const menuReturnButton = "data-menu-return";

        if (
            e.target
                .closest(".menu__item")
                .classList.contains("menu__item--parent") ||
            e.target
                .closest(".menu__item")
                .classList.contains("menu__item--mega-menu")
        ) {
            e.preventDefault();
            e.target
                .closest(".menu__item--parent")
                .querySelector("[data-menu-section]")
                .classList.add("is-active");
        }

        if (
            e.target.classList.contains("btn-return") ||
            e.target.parentNode.classList.contains("btn-return")
        ) {
            e.target
                .closest("[data-menu-section]")
                .classList.remove("is-active");
        }
    }

    animation() {
        const mobileAnimation = gsap.timeline({
            paused: true,
            repeatRefresh: true,
            onReverseComplete: () => {
                this.iconToggle.classList.remove("is-active");
                this.menuContainer.classList.remove("is-active");
            },
        });

        mobileAnimation.add(
            new animateBackground().add(
                new menuAnimation({
                    container: "[data-menu-container-mobile]",
                    el: "[data-animation-text]",
                }),
                ">-0.3"
            )
        );

        return mobileAnimation;
    }
}

class hamburgerMenuDesktop extends hamburgerMenu {
    constructor(iconToggle, menuContainer, matchMedia, overlay = null) {
        super(iconToggle, menuContainer, matchMedia);
        this.overlay = document.querySelector(overlay);
    }

    active() {
        this.controller = new AbortController();
        this.submenuItem = document.querySelectorAll(".mega-menu-submenu");
        this.menuItems = document.querySelectorAll(
            "[data-menu-item-hover-index]"
        );

        this.setMenuEventListener();

        window.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                if (this.menuIsOpen) {
                    this.menuToggle();
                }
            }
        });
        super.active();
    }

    destroy() {
        this.iconToggle.removeEventListener("click", this.clickHandler);
        this.menuContainer.removeEventListener("click", this.sectionToggle);
        this.menuClose();
    }

    setMenuEventListener() {
        this.menuItems.forEach((menuItem) => {
            menuItem.addEventListener(
                "mouseenter",
                (e) => {
                    this.setActiveItem(menuItem, this.getItemID(menuItem));
                },
                { signal: this.controller.signal }
            );
        });
    }

    getItemID(item) {
        return item.getAttribute("data-menu-item-hover-index");
    }

    setActiveItem(menuItem, id) {
        let depthContainer = menuItem.closest("[data-parent-depth]");
        if (!depthContainer) {
            return;
        }
        let depth = depthContainer.getAttribute("data-parent-depth");
        depthContainer.setAttribute("data-active-menuitem-id", id);
        depthContainer.classList.add("active");

        let submenu = document.querySelector(
            `[data-menu-item-group-index="${id}"]`
        );

        if (submenu) {
            if (depth === "2") {
                let subsubmenu = document.querySelector(
                    `[data-menu-item-group-index="${id}"]`
                );

                if (subsubmenu) {
                    this.closeMenusDepth(3);
                    submenu.classList.add("is-active");
                }
            } else {
                this.closeMenusDepth(2);
                submenu.classList.add("is-active");
            }
        }

        if (depth === "1" && !submenu) {
            this.removeAllSubitems();
        }

        if (depth === "2" && !submenu) {
            this.closeMenusDepth(3);
        }
    }

    removeAllSubitems() {
        if (!this.submenuItem) {
            return;
        }

        this.submenuItem.forEach((submenu) => {
            if (submenu.classList.contains("is-active")) {
                submenu.classList.remove("is-active");
            }
        });
    }

    closeMenusDepth(depth) {
        let col = document.querySelector(`[data-parent-depth="${depth}"]`);

        col.querySelectorAll("[data-menu-item-group-index]").forEach(
            (submenu) => {
                submenu.classList.remove("is-active");
            }
        );
    }

    menuOpen() {
        this.iconToggle.classList.add("is-active");
        this.menuContainer.classList.add("is-active");
        this.overlay?.classList.add("is-active");
        this.active();
        this.header.setAttribute("data-menu", "open");
        this.mainMenu = document.querySelector('.main-header__nav');
        this.mainMenu.classList.add('d-none');

        // Automatically open the first item with a submenu
        const firstMenuItem = document.querySelector('.menu__item--dropdown-horitonzal');
        if (firstMenuItem) {
            this.setActiveItem(firstMenuItem, this.getItemID(firstMenuItem));
        }
    }

    menuClose() {
        if (this.controller) {
            this.controller.abort();
        }
        this.removeAllSubitems();
        this.mainMenu = document.querySelector('.main-header__nav');
        this.mainMenu.classList.remove('d-none');
        this.iconToggle.classList.remove("is-active");
        this.menuContainer.classList.remove("is-active");
        this.overlay?.classList.remove("is-active");
        this.header.setAttribute(
            "data-menu",
            localStorage.getItem("header-theme")
        );
    }
}

if (!document.body.classList.contains("wp-admin")) {
    const mobileMenu = new hamburgerMenu(
        "[data-menu-icon-mobile]",
        "[data-menu-container-mobile]",
        "(max-width: 992px)"
    );

    const desktopMenu = new hamburgerMenuDesktop(
        "[data-menu-icon-desktop]",
        "[data-menu-container-desktop]",
        "(min-width: 992px)",
    );
}